import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js/auto"
import type { ChartTypeRegistry } from "chart.js";

declare const pattern: typeof import("patternomaly");

export default class extends Controller<HTMLElement>{
  static readonly targets = ["chart"]
  static readonly values = {
    ct: String,
    cd: Object,
    co: Object,
  }
  chart: Chart | undefined;

  connect() {
    const parentElement = this.element.parentElement;
    const canvasChildElement = parentElement?.querySelector("canvas")

    if (!canvasChildElement) {
      const chartElement = document.createElement('canvas');
      if (this.element.dataset.chartLabelledby) {
        chartElement.setAttribute('aria-labelledby', this.element.dataset.chartLabelledby)
      }
      parentElement?.appendChild(chartElement)
      this.element.style.display = 'none'

      const type = this.ctValue;
      const data = this.cdValue;
      const options = this.coValue;

      if (data) {
        this.chart = new Chart(
          chartElement,
          {
            type,
            data,
            options,
          })
      } else {
        console.warn('No data provided for chart')
      }
    }
  }

  disconnect(): void {
    this.chart?.destroy();
  }

  addPatterns(data: { datasets: { backgroundColor: string }[] }) {
    const patterns = [
      'dot',
      'diagonal',
      'square',
      'diamond',
      'dot',
      'zigzag',
      'triangle',
      'dash',
      'line',
      'line-vertical'
    ] as const;

    return {
      ...data,
      datasets: data.datasets.map((dataset, index) => {

        const colorsWithPatterns = Array.isArray(dataset.backgroundColor)
          ? dataset.backgroundColor.map((color, i) => pattern.draw(patterns[i], color))
          : pattern.draw(patterns[index], dataset.backgroundColor)

        return {
          ...dataset,
          backgroundColor: colorsWithPatterns
        }
      })
    }
  }

  declare readonly ctValue: keyof ChartTypeRegistry;
  declare readonly cdValue: any;
  declare readonly coValue: any;
}
